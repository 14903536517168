import React from 'react';
import './style.scss';
import BottomMenu from './bottom-nav'

const HeroSection = () => (
    <div>
        <div className="hero">
            <div className="columns is-multiline is-centered is-gapless">
                <div className="column is-10">
                    <div className="hero-padding">
                        <div className="container">
                            <h1 className="accent margin-bot-0">Hello,</h1>
                            <h1 className="is-hidden-mobile">I’m Willy Ahmad Syafiq</h1>
                            <h1 className="is-hidden-tablet">I’m Willy <br />Ahmad Syafiq</h1>
                            <h3>Product Designer based in Jakarta,<br />I do design, research, and front-end stuff.</h3>
                        </div>
                    </div>
                    <div className="line-strip"></div>
                </div>
                <BottomMenu />
            </div>
        </div>
    </div>
)

export default HeroSection;