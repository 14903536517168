import React from 'react';
import './style.scss';
import { Link } from 'gatsby';
import logo from '../images/logo-light.svg'

const NavbarHome = () => (
    <div className="topbar" >
        <div className="navbar-menu">
            <div className="navbar-start">
                <Link to="/">
                    <img className="logo-type" src={logo} alt="logo" />
                </Link>
            </div>
        </div>
    </div>
);

export default NavbarHome;
