import React from 'react';
import './style.scss';
import NavbarHome from './navbar-home';
import NavbarMobile from './navbar-mobile';

const HeaderHome = ({ siteTitle }) => (
    <div className="columns is-centered is-gapless">
        <div className="column is-10 is-hidden-mobile">
            <NavbarHome />
        </div>
        <div className="column is-12 is-hidden-tablet">
            <NavbarMobile />
        </div>
    </div>
);

export default HeaderHome;
