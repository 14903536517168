import React from 'react';
import './style.scss';
import { Link } from 'gatsby';

const BottomNav = () => (
    <div className="column is-12 bottom-menu is-hidden-mobile">
        <div className="columns is-centered is-gapless">
            <div className="column is-10">
                <p className="menu-text">MENU</p>
                <Link to="/case-studies">Case Studies</Link>
                <Link to="/writings">Writings</Link>
                <Link to="/resume">Resume</Link>
                <Link to="/contact">Contact</Link>
            </div>
        </div>
    </div>
);

export default BottomNav;
