import React from 'react';
import Helmet from '../components/helmet';
import HeroSection from '../components/hero'
import HeaderHome from '../components/header-home';

const IndexPage = () =>
    <section>
        <div className="hero-bg">
            <Helmet />
            <HeaderHome />
            <HeroSection />
        </div>
    </section>

export default IndexPage;
